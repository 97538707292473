import React from "react"
import SEO from '../../components/seo'
import { Link } from "gatsby"

import Hakusi from '../../layouts/hakusi'


export default () => (


  <Hakusi>

	<SEO title="利用規約"/>

	<div class="osirase">

<h1>利用規約</h1>

<p>本商品を利用する際は、以下の規約に同意するものとします。また、本規約が予告なく変更・更新される場合があることを、利用者は承諾するものとします。</p>

<h2>1.コンテンツの譲渡ならびにコンテンツのコピー、公開の禁止 </h2>

<p>有償無償を問わず、コンテンツの内容を譲渡・販売すること、公衆に公開すること、複製すること、内容をコピー・盗用することを禁じます。万一、これらの行為を行なった場合は、直ちに顧問弁護士に相談の上、法的措置を取らせていただきます。また、内容をコピーしているものである場合は、ライセンス料を請求いたしますので、それを支払うことに同意するものとします。</p>

<p>ライセンス料＝【本商品販売価格の100%+コンテンツによって生じた利益】×使用した回数 </p>

<h2>2.支払いの義務</h2>

			<p>分割払いで購入した場合は、購入時に指定した支払い回数と支払い期間に従って、支払いを完了するものとします。<strong>未払いが出た場合は、分割手数料を含む残額を即時一括請求いたします</strong>ので、それを支払うことに同意するものとします。万一、悪質だと判断した場合は、誠に不本意ですが、顧問弁護士に相談の上、法的手続きを取らせていただきます。</p>

<p class='t-center'><Link to="/elements/start/">教材へ戻る</Link></p>


	</div>


  </Hakusi>
  )